const OnTap = {
  onTapSlider: function() {
    $(".beer-slider").slick({
      arrows: false,
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    });
  },

  hover: function() {
    const beerContent = $(".beer-slider--content");
    const beerDesc = $(".beer-slider--beer-desc");

    beerContent.hover(
      function() {
        $(this)
          .find(".beer-slider--beer-desc")
          .removeClass("hide");
      },
      function() {
        $(this)
          .find(".beer-slider--beer-desc")
          .addClass("hide");
      }
    );
  },

  init: function() {
    this.onTapSlider();
    // this.hover();
  }
};

export default OnTap;
