const Filters = {
  news: function() {
    let news = $(".post--filter ul");

    news.on("click", "li", function() {
      let target = $(".post-box--container");
      let year = $(this).attr("data-year");

      $.ajax({
        type: "post",
        url: AjaxHandler.ajaxurl,
        data: { action: "year_filter", year: year }
      }).success(function(data) {
        target.empty();
        target
          .append(data)
          .fadeOut(10)
          .fadeIn(350);
      });
    });
  },
  filterButton: function() {
    let target = $(".post--filter ul");

    target.on("click", "li", function() {
      $("li").removeClass("post-filter--active");
      $(this).addClass("post-filter--active");
    });
  },

  init: function() {
    this.news();
    this.filterButton();
  }
};

export default Filters;
