const Header = {
  nav: function() {
    let hamburger = $(".hamburger");

    hamburger.on("click", function() {
      $(this).toggleClass("clicked");
    });
  },
  fixed: function() {
    const nav = $(".primary-nav");
    const trigger = $(".close-nav");
    const mqMin = window.matchMedia("(min-width: 769px)");
    const mqMax = window.matchMedia("(max-width: 769px)");

    $(window).scroll(function() {
      let scroll = $(window).scrollTop();
      if (scroll >= 1 && mqMin.matches) {
        nav.addClass("fixed");
        // $('header').animate({'height':'85px'});
      } else {
        nav.removeClass("fixed");
        // $('header').animate({'height':'100%'});
      }
    });

    if (mqMax.matches) {
      trigger.on("click", function() {
        nav.toggleClass("slide-down");
      });
    }
    if (mqMin.matches) {
      trigger.on("click", function() {
        nav.removeClass("fixed");
        nav.toggleClass("slide-down");
      });
    }
  },

  dropdown: function() {
    let target = $(".has-dropdown");

    if (target) {
      target.on("click", function() {
        target.find("ul").toggleClass("show-dropdown");
      });
    }
  },

  init: function() {
    this.nav();
    this.fixed();
    this.dropdown();
  }
};

export default Header;
