import header from "./components/header";
import filters from "./components/filters";
import ontap from "./components/on-tap";
import maps from "./components/map";
import beers from "./components/beers";
import slider from "./components/slider-module";

header.init();
beers.init();
filters.init();
maps.init();
ontap.init();
slider.init();

//
// Helps with debugging PHP on the front end
// instead of prin_r or var_dump use code($array)
// -----------------------------------------------------------------------------

var isDeclared = true;

try {
  hljs;
} catch (e) {
  if (e.name == "ReferenceError") {
    isDeclared = false;
  }
}

if (isDeclared) {
  hljs.initHighlightingOnLoad();
}

// $('.woocommerce-LoopProduct-link').on('click',function(e){
//     e.preventDefault();
// });
