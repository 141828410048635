const mapStyle = [
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: 36
      },
      {
        color: "#000000"
      },
      {
        lightness: 40
      }
    ]
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on"
      },
      {
        color: "#000000"
      },
      {
        lightness: 16
      }
    ]
  },
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000"
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#000000"
      },
      {
        lightness: 17
      },
      {
        weight: 1.2
      }
    ]
  },
  {
    featureType: "administrative",
    elementType: "labels",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "administrative.country",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified"
      }
    ]
  },
  {
    featureType: "administrative.country",
    elementType: "geometry",
    stylers: [
      {
        visibility: "simplified"
      }
    ]
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "simplified"
      }
    ]
  },
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "administrative.locality",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified"
      },
      {
        saturation: "-100"
      },
      {
        lightness: "30"
      }
    ]
  },
  {
    featureType: "administrative.neighborhood",
    elementType: "all",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "all",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified"
      },
      {
        gamma: "0.00"
      },
      {
        lightness: "74"
      }
    ]
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000"
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: "landscape.man_made",
    elementType: "all",
    stylers: [
      {
        lightness: "3"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000"
      },
      {
        lightness: 21
      }
    ]
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        visibility: "simplified"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000"
      },
      {
        lightness: 17
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#000000"
      },
      {
        lightness: 29
      },
      {
        weight: 0.2
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000"
      },
      {
        lightness: 18
      }
    ]
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000"
      },
      {
        lightness: 16
      }
    ]
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000"
      },
      {
        lightness: 19
      }
    ]
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000"
      },
      {
        lightness: 17
      }
    ]
  }
];

const Maps = {
  map: function() {
    const mapContainer = document.getElementById("map");

    if (!mapContainer) {
      return;
    }

    const key = "AIzaSyAcCojJN-seAsM6fBrpRT6BiPJzT7PBywU";

    const bounds = new google.maps.LatLngBounds();
    // const iconsLocation = `${AjaxHandler.tpl_dir}/assets/build/img/marker.png`;
    const allLocations = locations; //declared in locations.php
    let markers = [];
    let marker = [];
    // let infoWindow;

    let map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 43.67262, lng: -79.47826 },
      zoom: 8,
      styles: mapStyle
    });

    let infoWindow = new google.maps.InfoWindow({
      content: " "
    });

    for (let location of allLocations) {
      const { name, content, lat, lng } = location;
      let latLng = new google.maps.LatLng(lat, lng);
      let infoWindowContent = `<p>${name}</p>`;
      marker = new google.maps.Marker({
        map: map,
        position: latLng
      });

      bounds.extend(latLng);
      map.fitBounds(bounds);

      google.maps.event.addListener(marker, "click", function() {
        infoWindow.close();
        infoWindow.setContent(infoWindowContent);
        infoWindow.open(map, this);
      });
    }
  },

  overlay: function() {
    const trigger = $("#all-locations");

    trigger.on("click", function() {
      $(this).toggleClass("slide-up");

      let text = $(".all-locations--cta h5").text();
      $(".all-locations--cta h5").text(
        text == "See All Locations" ? "Close" : "See All Locations"
      );
    });
  },

  init() {
    this.map();
    this.overlay();
  }
};

export default Maps;
