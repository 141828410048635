const Beers = {
  reveal: function() {
    let beers = $("#beers");
    const mq = window.matchMedia("(max-width: 769px)");

    $(".single").addClass("single-beer--animation");

    beers.on("click", ".single-beer", function() {
      if (mq.matches) {
      } else {
        $("html,body").animate({ scrollTop: $(this).offset().top }, "slow");
        $(this).toggleClass("single-beer--collapsed");
        $(this)
          .find(".single-beer--section--container")
          .toggleClass("single-beer--animation");
        $(".single-beer--related").toggleClass("single-beer--animation");
      }
    });
  },
  init: function() {
    this.reveal();
  }
};

export default Beers;
